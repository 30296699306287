import { css } from "styled-components"

export const theTeam = css`
  
  .the-team {

    &--first-part {

      .text {
        font-size: 24px;
        line-height: 34px;
      }

      .look-at-us-now {
        margin-bottom: 60px;

        @media (max-width: 768px) {
          .section-title {
            margin-bottom: 30px;
          }
        }
      }

      .texts {

        margin-top: 28px;
        display: flex;

        @media (max-width: 768px) {
          flex-direction: column;
        }

        &__left {
          margin-right: 44px;
        }

        &__left,
        &__right {
          flex-basis: 50%;
        }

      }

    }

    /* 
      @TODO - Maybe fallback to plain classes, since we might not
                need BEM, as we are already scoping this CSS with
                styled-components.
    */

    &--second-part {
      margin-top: 65px;
    }

    .team-roster {

      width: 100%;
      display: flex;
      flex-wrap: wrap;
      align-content: stretch;

      @media (max-width: 768px) {
        justify-content: center;
      }

      &__card {

        flex-basis: 267px;
        flex-shrink: 0;
        margin-bottom: 52px;

        &:not(:last-child) {
          margin-right: 24px;
        }

        @media (max-width: 768px) {
          margin: 0;
          margin-bottom: 52px;
        }
        
        &--container {
          position: relative;
          height: 370px;
          width: 100%;
          border-radius: 0px 0px 20px 0px;
          margin-bottom: 17px;
          overflow: hidden;
        }

        &__photo {
          position: absolute;
          width: 100%;
          height: 100%;
          background-size: cover;

          &.pc {

            &--jeca {
              background-image: url("/jelena-dinic-2--podroom-creative.jpg");
            }

            &--paxon {
              background-image: url("/pavle-dinic--podroom-creative.jpg");
            }

            &--zuki {
              background-image: url("/nemanja-manojlovic--podroom-creative.jpg");
            }

            &--brile {
              background-image: url("/vladimir-ilic--podroom-creative.jpg");
            }


            &--you {
              background-position-x: -40px;
              background-image: url("/placeholder-profile-sq.jpg");
            }

            &--nenad {
              background-image: url("/Nenad.jpg");
              background-position-x: -55px;
            }

            &--miljana {
              background-image: url("/Miljana.jpg");
              background-position-x: -55px;
            }
          }
        }

        &__content {

          display: none;
          background-color: rgba(72, 228, 183, 0.75);
          position: absolute;
          height: 100%;
          width: 100%;
          box-sizing: border-box;
          padding: 20px;
          padding-top: 30px;

          &__logo {
            width: 35px;
            margin-bottom: 3px;

            > img {
              width: 100%;
            }
          }

          &__text {
            margin-bottom: 20px;
            font-size: 14px;
            line-height: 22px;
          }

          &__links {

            display: flex;

            &__link {

              background-color: white;
              width: 24px;
              height: 24px;
              border-radius: 24px;
              background-size: contain;
              
              &:not(:first-child) {
                margin-left: 10px;
              }

              &:hover {
                filter: invert(100%);
              }

              &.up {
                background-image: url("/ico_upwork.png");
              }
              
              &.ln {
                background-image: url("/ico_linkedin.png");
                background-position-x: 1px;
              }

              &.fb {
                background-image: url("/ico_facebook.png");
              }

              &.tw {
                background-image: url("/ico_twitter.png");
              }

              &.in {
                background-image: url("/ico_instagram.png");
              }

            }

          }

        } /* end of &__content */

        &:hover {

          .team-roster__card__content {
            display: block;
          }

        }

        &--bottom {
          /*  */
        }

        &__person-name {
          font-size: 24px;
          line-height: 26px;
          font-weight: bold;
          margin-bottom: 5px;
        }

        &__person-title {
          font-size: 17px;
          line-height: 26px;
        }
        
      } /* end of &__card */

    } /* end of .team-roster */

  } /* end of .the-team */

`
